<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="transparent" flat>
          <v-card-title class="title font-weight-bold">
            소식 관리
          </v-card-title>
          <v-card-subtitle>
            기사 정보를 관리하는 페이지입니다.
          </v-card-subtitle>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card class="pa-4 rounded-lg" outlined>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn class="mr-2" icon @click="list" :disabled="fetchLoading">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <admin-add-press-dialog :data="items" />
          </v-card-title>

          <v-card-text>
            <v-data-iterator
              class="body-2"
              :loading="fetchLoading"
              :items="items"
              :page.sync="page"
              :options.sync="options"
              :server-items-length="totalCount"
              :items-per-page="itemsPerPage"
              @page-count="pageCount = $event"
              hide-default-footer
              no-data-text="검색된 데이터가 없습니다"
              no-results-text="매칭되는 기록이 없습니다"
              loading-text="로딩 중입니다."
              disable-sort
            >
              <template v-slot:default="props">
                <v-row>
                  <v-col cols="12" v-if="fetchLoading" class="text-center">
                    <v-card color="transparent" flat v-if="fetchLoading">
                      <v-card-text class="text-center">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-card-text>
                      <v-card-text class="text-center">
                        데이터를 불러오는 중입니다.
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col
                    v-else
                    v-for="item in props.items"
                    :key="item.name"
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <admin-press-dialog :content="item" :data="items" />
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </v-card-text>
          <v-pagination v-model="page" :length="pageCount" />
        </v-card>
        <div></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import setMeta from "@/utils/setMeta";
import AdminAddPressDialog from "@/components/Dialog/Admin/Press/AdminAddPressDialog.vue";
import AdminPressDialog from "@/components/Dialog/Admin/Press/AdminPressDialog.vue";

export default {
  components: { AdminAddPressDialog, AdminPressDialog },
  name: "Home",

  beforeCreate() {
    setMeta({
      title: "소식 관리",
      description: "메이드올 관리자페이지입니다.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FOG%2Flogin.png?alt=media&token=171e59e8-a13a-43eb-83f1-5f2b0a5629a6",
    });
  },
  watch: {
    options: {
      handler() {
        this.list();
      },
      deep: true,
    },
    email(n, o) {
      if (n !== o) this.list();
    },
  },
  methods: {
    list() {
      this.fetchLoading = true;
      this.$axios
        .get("admin/press", {
          params: {
            offset: this.page > 0 ? (this.page - 1) * this.itemsPerPage : 0,
            limit: this.itemsPerPage,
            order: this.options.sortBy[0],
            sort: this.options.sortDesc[0] ? "desc" : "asc",
            search: this.email,
          },
        })
        .then(({ data }) => {
          this.totalCount = data.totalCount;
          this.items = data.items;
        })
        .catch((e) => {
          this.$toasted.global.error(e.message);
        })
        .finally(() => {
          this.fetchLoading = false;
        });
    },
  },
  data() {
    return {
      fetchLoading: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      options: {
        sortBy: ["createdAt"],
        sortDesc: [true],
      },
      totalCount: 0,
      items: [],
    };
  },
};
</script>
