<template>
  <v-dialog
    class="elevation-0"
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    scrollable
    max-width="1080px"
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        @click="editItem()"
        v-bind="attrs"
        v-on="on"
        :ripple="false"
        color="primary"
        class="rounded-lg body-2 font-weight-bold"
        depressed
        >소식 추가</v-btn
      >
    </template>
    <v-card flat class="rounded-xl">
      <v-card-title class="font-weight-bold">
        <!-- <span class="">주문 정보</span> -->

        <v-spacer></v-spacer>

        <v-btn
          v-if="$vuetify.breakpoint.smAndDown"
          rounded
          icon
          @click="close"
          :ripple="false"
        >
          <feather type="x-circle" />
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="4" md="2" align-self="center"> 생성일 </v-col>
            <v-col cols="8" md="10">
              {{ $moment(editedItem.createdAt, "YYYY-MM-DD HH:mm") }}
            </v-col>
            <v-col cols="4" md="2" align-self="center"> 마지막 수정 </v-col>
            <v-col cols="8" md="10">
              {{ $moment(editedItem.updatedAt, "YYYY-MM-DD HH:mm") }}
              |
              <span>
                {{ editedItem.uid }}
                <v-btn small color="grey lighten-1" icon>
                  <feather size="18" type="copy" />
                </v-btn>
              </span>
            </v-col>

            <v-col cols="4" md="2" align-self="center"> 커버 이미지 </v-col>
            <v-col cols="8" md="10">
              <v-card
                class="surface rounded-xl"
                max-height="320"
                max-width="320"
                flat
              >
                <v-img :aspect-ratio="4 / 3" :src="tempUrl">
                  <v-file-input
                    class="ma-0 pa-0"
                    style="
                      height: 100%;
                      align-items: center;
                      justify-content: center;
                    "
                    accept="image/*"
                    height="100%"
                    color="onSurface"
                    v-model="tempImageFile"
                    hide-input
                    prepend-icon="mdi-camera"
                  />
                </v-img>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem.title"
                max-width="100"
                class="title font-weight-bold onSurface--text"
                hide-details
                solo
                flat
                background-color="surface"
                label="제목"
              >
                <template v-slot:append-outer>
                  <v-btn
                    @click="editedItem.locked = !editedItem.locked"
                    small
                    color="grey lighten-1"
                    icon
                  >
                    <feather v-if="editedItem.locked" size="18" type="lock" />
                    <feather v-else size="18" type="unlock" />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="editedItem.subtitle"
                class="subtitle onSurface--text"
                hide-details
                solo
                no-resize
                flat
                background-color="surface"
                label="부제목"
              >
              </v-textarea>
            </v-col>

            <v-col cols="4" md="2" align-self="center"> 기사 링크 </v-col>
            <v-col cols="8" md="10">
              <v-text-field
                v-model="editedItem.url"
                class="onSurface--text"
                hide-details
                solo
                flat
                background-color="surface"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          class="rounded-lg"
          text
          @click="close"
          :ripple="false"
        >
          취소
        </v-btn>
        <v-btn
          color="primary"
          class="rounded-lg font-weight-bold"
          depressed
          :block="$vuetify.breakpoint.smAndDown"
          @click="save"
          :loading="buttonLoading"
        >
          추가
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import firestorageUtils from "@/utils/firestorage";
import _ from "lodash";
import constants from "@/utils/constants";

export default {
  components: {},
  props: {
    data: Array,
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {},
  methods: {
    editItem() {
      this.editedItem = _.cloneDeep(constants.pressInterface);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = _.cloneDeep(constants.pressInterface);
        this.tempImageFile = null;
      });
    },

    save() {
      this.uploadPress();
    },
    async uploadPress() {
      this.buttonLoading = true;

      this.$toasted.global.notice("업로드 중입니다.");

      const press = await this.$axios.put("admin/addPress/", {
        uid: this.$store.getters["auth/user/GET_USER"].uid,
        item: this.editedItem,
      });
      this.editedItem.id = press.data.id;
      this.editedItem.createdAt = press.data.data.createdAt;

      if (this.tempImageFile) {
        const result = await firestorageUtils.uploadImageFile({
          file: this.tempImageFile,
          path: `Press/${this.editedItem.id}/thumbnail/${this.tempImageFile.name}`,
        });

        this.editedItem.filePath = result.filePath;
        this.editedItem.imageURL = result.imageURL;
      }

      await this.$axios.put("admin/updatePress/" + this.editedItem.id, {
        uid: this.$store.getters["auth/user/GET_USER"].uid,
        item: this.editedItem,
      });

      this.data.push(this.editedItem);
      this.close();
      this.buttonLoading = false;
      this.$toasted.global.success("업로드 완료!");
    },
  },
  computed: {
    tempUrl() {
      if (!this.tempImageFile) return;
      return URL.createObjectURL(this.tempImageFile);
    },
  },
  data() {
    return {
      dialog: false,
      tempImageFile: null,
      buttonLoading: false,

      editedItem: {},
    };
  },
};
</script>

<style></style>
