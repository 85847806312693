<template>
  <v-card
    class="rounded-lg"
    :href="lock ? '' : content.url"
    target="_blank"
    :ripple="false"
    outlined
  >
    <v-img
      :aspect-ratio="4 / 3"
      class="surface"
      max-height="240"
      :src="content.imageURL"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <!-- <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular> -->
        </v-row>
      </template>
    </v-img>
    <v-card-title
      two-line
      class="title font-weight-bold"
      :class="$vuetify.breakpoint.mdAndUp ? 'title' : 'subtitle-1'"
    >
      {{ content.title }}
    </v-card-title>
    <v-card-text v-if="$vuetify.breakpoint.mdAndUp" two-line>
      {{ content.subtitle }}
    </v-card-text>

    <v-card-subtitle class="caption">
      {{ content.createdAt | moment("YYYY-MM-DD ") }}
    </v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  props: {
    content: Object,
    lock: Boolean,
  },
};
</script>

<style></style>
