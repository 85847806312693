<template>
  <v-dialog
    class="elevation-0"
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    scrollable
    max-width="1080px"
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <div @click="editItem(content)" v-bind="attrs" v-on="on">
        <press-card :lock="true" :content="content" />
      </div>
    </template>
    <v-card flat class="rounded-xl">
      <v-card-title class="font-weight-bold">
        <!-- <span class="">주문 정보</span> -->

        <v-dialog v-model="dialogDelete" max-width="320px">
          <v-card class="rounded-xl text-center pa-2" flat>
            <v-card-title class="title font-weight-bold">
              선택한 항목을 삭제하겠습니까?
            </v-card-title>

            <v-card-subtitle class="ma-0">
              해당 항목이 즉시 삭제됩니다. 이 동작은 실행 취소할 수 없습니다.
            </v-card-subtitle>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary rounded-lg" depressed @click="closeDelete">
                취소
              </v-btn>
              <v-btn
                color="surface rounded-lg"
                depressed
                @click="deleteItemConfirm"
              >
                삭제
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn
          color="error"
          class="rounded-lg font-weight-bold"
          depressed
          @click="deleteItem(content)"
          icon
        >
          <feather type="trash" />
        </v-btn>

        <v-spacer></v-spacer>
        <v-btn
          v-if="$vuetify.breakpoint.smAndDown"
          rounded
          icon
          @click="close"
          :ripple="false"
        >
          <feather type="x-circle" />
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="4" md="2" align-self="center"> 생성일 </v-col>
            <v-col cols="8" md="10">
              {{ $moment(editedItem.createdAt, "YYYY-MM-DD HH:mm") }}
              <v-spacer></v-spacer>
            </v-col>
            <v-col cols="4" md="2" align-self="center"> 마지막 수정 </v-col>
            <v-col cols="8" md="10">
              {{ $moment(editedItem.updatedAt, "YYYY-MM-DD HH:mm") }} |
              <span>
                {{ editedItem.uid }}
                <v-btn
                  v-clipboard:copy="editedItem.uid"
                  v-clipboard:success="
                    () => {
                      $toasted.global.success('클립보드에 저장했습니다.');
                    }
                  "
                  v-clipboard:error="
                    () => {
                      $toasted.global.error('복사를 실패했습니다.');
                    }
                  "
                  small
                  color="grey lighten-1"
                  icon
                >
                  <feather size="18" type="copy" />
                </v-btn>
              </span>
            </v-col>

            <v-col cols="4" md="2" align-self="center"> 커버 이미지 </v-col>
            <v-col cols="8" md="10">
              <v-card
                class="surface rounded-xl"
                max-height="320"
                max-width="320"
                flat
              >
                <v-img
                  v-if="tempImageFile"
                  :aspect-ratio="4 / 3"
                  :src="tempUrl"
                >
                  <v-file-input
                    class="ma-0 pa-0"
                    style="
                      height: 100%;
                      align-items: center;
                      justify-content: center;
                    "
                    accept="image/*"
                    height="100%"
                    color="onSurface"
                    v-model="tempImageFile"
                    hide-input
                    prepend-icon="mdi-camera"
                  />
                </v-img>
                <v-img v-else :aspect-ratio="4 / 3" :src="editedItem.imageURL">
                  <v-file-input
                    class="ma-0 pa-0"
                    style="
                      height: 100%;
                      align-items: center;
                      justify-content: center;
                    "
                    accept="image/*"
                    v-model="tempImageFile"
                    hide-input
                    hide-details
                    prepend-icon="mdi-camera"
                  />
                </v-img>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem.title"
                max-width="100"
                class="title font-weight-bold onSurface--text"
                hide-details
                solo
                flat
                background-color="surface"
                label="제목"
              >
                <template v-slot:append-outer>
                  <v-btn
                    @click="editedItem.locked = !editedItem.locked"
                    small
                    color="grey lighten-1"
                    icon
                  >
                    <feather v-if="editedItem.locked" size="18" type="lock" />
                    <feather v-else size="18" type="unlock" />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="editedItem.subtitle"
                class="subtitle onSurface--text"
                hide-details
                solo
                no-resize
                flat
                background-color="surface"
                label="부제목"
              >
              </v-textarea>
            </v-col>

            <v-col cols="4" md="2" align-self="center"> 기사 링크 </v-col>
            <v-col cols="8" md="10">
              <v-text-field
                v-model="editedItem.url"
                class="onSurface--text"
                hide-details
                solo
                flat
                background-color="surface"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>

        <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          class="rounded-lg"
          text
          @click="close"
          :ripple="false"
        >
          취소
        </v-btn>
        <v-btn
          color="primary"
          class="rounded-lg font-weight-bold"
          depressed
          :block="$vuetify.breakpoint.smAndDown"
          @click="save"
          :loading="buttonLoading"
        >
          저장
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import firestorageUtils from "@/utils/firestorage";
import PressCard from "../../../Card/PressCard.vue";
import _ from "lodash";
import constants from "@/utils/constants";

export default {
  components: { PressCard },
  props: {
    data: Array,
    content: Object,
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.editedItem = _.cloneDeep(constants.pressInterface);
  },
  mounted() {},
  methods: {
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign(
        _.cloneDeep(constants.pressInterface),
        item,
      );
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await this.$axios.delete("admin/deletePress/" + this.editedItem.id);
      this.data.splice(this.editedIndex, 1);
      this.closeDelete();
      this.close();
    },

    close() {
      this.dialog = false;
      this.tempImageFile = null;
      this.$nextTick(() => {
        this.editedItem = _.cloneDeep(constants.pressInterface);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    save() {
      this.updatePress();
    },

    async updatePress() {
      this.buttonLoading = true;

      this.$toasted.global.notice("업데이트 중입니다.");
      if (this.tempImageFile) {
        const result = await firestorageUtils.uploadImageFile({
          file: this.tempImageFile,
          path: `Press/${this.editedItem.id}/thumbnail/${this.tempImageFile.name}`,
        });

        this.editedItem.filePath = result.filePath;
        this.editedItem.imageURL = result.imageURL;
      }

      await this.$axios.put("admin/updatePress/" + this.editedItem.id, {
        uid: this.$store.getters["auth/user/GET_USER"].uid,
        item: this.editedItem,
      });

      this.$toasted.global.success("업데이트 완료!");

      if (this.editedIndex > -1) {
        Object.assign(this.data[this.editedIndex], this.editedItem);
      } else {
        this.data.push(this.editedItem);
      }
      this.close();
      this.buttonLoading = false;
    },
  },
  computed: {
    tempUrl() {
      if (!this.tempImageFile) return;
      return URL.createObjectURL(this.tempImageFile);
    },
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      tempImageFile: null,
      buttonLoading: false,

      editedIndex: -1,
      editedItem: {},
    };
  },
};
</script>

<style></style>
